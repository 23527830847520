import Api from "../../lib/Api";
import { selectWhitelabelInfo } from "../selectors/whitelabel";
import { WEHITELABEL_INFO_LOADED, WEHITELABEL_INFO_NOT_FOUND } from "./whiteLabelActionTypes";




const loadWhitelabelInfo = () => (dispatch, getState) => {
    const whitelabel = selectWhitelabelInfo(getState());

    // prevent unnecessary requests if we already have the correct data
    if (whitelabel && whitelabel.hostname === window.location.hostname) {
        return;
    }

    const domain = window.location.hostname;

    const url = `/whitelabel/${domain}`;
    Api.get(url).then(response => {
        const data = response.data?.response?.data;
        if (data) {
            // WEHITELABEL_INFO_LOADED
            dispatch({
                type: WEHITELABEL_INFO_LOADED,
                data
            })
        } else {
            // WEHITELABEL_INFO_NOT_FOUND
            dispatch({
                type: WEHITELABEL_INFO_NOT_FOUND,
                data: null
            })
        }
    });
}

export { loadWhitelabelInfo };
