import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../lib/Auth";
import Newsletter from '../../lib/Newsletter';
import Subscription from "../../lib/Subscription";
import PrivacyPolicyLink from "../Policy/PrivacyPolicyLink";
import TermsConditionsLink from "../Policy/TermsConditionsLink";
import './style.css';
import { selectCheckoutReturnUrl, selectExtensionDetails, selectSubscription } from "../../redux/selectors/checkout";
import Api from "../../lib/Api";
import Redirections from "../../lib/Redirections";
import { chooseSubscription, setReturnUrl } from "../../redux/actions/checkout";
import { reconstructChoices } from "../Bikefit/lib/bikefit";
import { choicesFetched } from "../../redux/actions/bikefit";
import Loader from "../Loader";

const CheckoutData = ({ discountTokenFromUrl = '' }) => {
  const [t,] = useTranslation('subscription')
  const returnUrl = useSelector(selectCheckoutReturnUrl)
  const subscription = useSelector(selectSubscription)
  const extensionDetails = useSelector(selectExtensionDetails)

  const [firstName, setFirstName] = useState(Newsletter.getLocalEntry().firstname || '')
  const [lastName, setLastName] = useState(Newsletter.getLocalEntry().lastname || '')
  const [email, setEmail] = useState(Newsletter.getLocalEntry().email || '')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const [optinMail, setOptinMail] = useState(Newsletter.getLocalEntry().optinMail || false)
  const [optinData, setOptinData] = useState(Newsletter.getLocalEntry().optinData || false)
  const [policy, setPolicy] = useState(Newsletter.getLocalEntry().policy || false)

  const inputFirstName = e => setFirstName(e.target.value)
  const inputLastName = e => setLastName(e.target.value)
  const inputEmail = e => setEmail(e.target.value)
  const inputPassword = e => setPassword(e.target.value)
  const inputPasswordRepeat = e => setPasswordRepeat(e.target.value)

  const changeOptinMail = e => setOptinMail(e.target.checked)
  const changeOptinData = e => setOptinData(e.target.checked)
  const changePolicy = e => setPolicy(e.target.checked)
  const isUserInfoPage = JSON.parse(localStorage.getItem('isUserInfoPage'))

  const dispatch = useDispatch();

  const cookieStorage = {
    getItem: (key) => {
      return localStorage.getItem(`cookie_${key}`)
    },
    setItem: (key, value) => {
      return localStorage.setItem(`cookie_${key}`, value)
    },
  };

  useEffect(() => {
    const hasGoneToPaymentPage = cookieStorage.getItem("hasGoneToPaymentPage");
    if (hasGoneToPaymentPage === 'true') {
      // redirect to checkout page...
      Redirections.goTo(`/subscription/payment/bikefit/`)
    }

    if (Auth.isLoggedIn() && Auth.getAccountType() === 'login') {
      if (!extensionDetails.isExtension) {
        // Placed it in a comment because it's not clear if we need it
        //alert(t('Account_DirectlyCheckout'));
      }

      cookieStorage.setItem('hasGoneToPaymentPage', 'true');
      Subscription.createOrderAndGoToPayment(
        subscription.type,
        subscription.code,
        returnUrl,
        extensionDetails.isExtension || false,
        extensionDetails.accountSubscriptionId || 0,
        null,
        null,
        discountTokenFromUrl
      );
    }
  }, []);

  useEffect(() => {
    if (discountTokenFromUrl) {
      processDiscountInfo()
    }
  }, [discountTokenFromUrl]);

  const processDiscountInfo = () => {
    Api.get(`discount/info/${discountTokenFromUrl}`)
      .then(response => {
        if (response.data?.response?.status === 1000) {
          const data = response.data?.response?.data;
          if (data?.purchased) {
            Redirections.goTo('/');
          } else {
            setFirstName(data?.newsletter?.firstname)
            setLastName(data?.newsletter?.lastname)
            setEmail(data?.newsletter?.email)
            setOptinData(data?.newsletter?.optin_data)
            setOptinMail(data?.newsletter?.optin_mail)
            setPolicy(data?.newsletter?.policy)
            dispatch(setReturnUrl(data?.return_url))
            const input = JSON.parse(data?.generated_result?.input)

            const choices = reconstructChoices(input);
            dispatch(choicesFetched(choices));
            Api.sessionId = data?.session_id

            dispatch(chooseSubscription(data?.subscription))
          }
        } else if (response.data?.response?.status === 1008) {
          alert(t('DiscountExpired'))
        } else {
          alert(t('GeneralError'))
        }
      });
  }

  const startCheckoutGA = subscription => {
    ReactGA.event('begin_checkout', {
      currency: subscription.price.currency,
      value: subscription.price.price / 100,
      items: [{
        item_id: subscription.code,
        item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
        item_category: subscription.type,
        price: subscription.price.price / 100,
        currency: subscription.price.currency
      }]
    });
  }

  const submit = e => {
    e.preventDefault()
    nextPage();
  }

  const nextPage = (replaceUrl = false) => {
    let errors = []

    errors = checkOnce();

    if (errors.length === 0) {
      startCheckoutGA(subscription);

      Newsletter.setEntry({
        firstname: firstName,
        lastname: lastName || '',
        email: email,
        optinMail: optinMail,
        optinData: optinData,
        policy: policy
      })

      cookieStorage.setItem('hasGoneToPaymentPage', 'true');
      Subscription.createOrderAndGoToPayment(
        subscription.type,
        subscription.code,
        returnUrl,
        extensionDetails.isExtension || false,
        extensionDetails.accountSubscriptionId || 0,
        firstName,
        email,
        discountTokenFromUrl,
        lastName,
        password,
        policy,
        optinData,
        optinMail,
        replaceUrl
      );
    } else {
      alert(errors.join("\n - "))
    }
  }

  useEffect(() => {
    if (!subscription) {
      return
    }

    if (isUserInfoPage) {
      nextPage(true);
    }
  }, [firstName, email, subscription]);

  const checkOnce = () => {
    let errors = []
    if ([firstName, email].filter(value => !value).length > 0) {
      errors = [...errors, t('Account_FillIn')]
    }

    if (!policy && !isUserInfoPage) {
      errors = [...errors, t('Order_AcceptPolicy')];
    }

    return errors;
  }

  const textPrefix = subscription?.needs_account ? 'Account_Create' : 'Order_Credentials'

  if (isUserInfoPage) {
    return (
      <Loader />
    )
  }

  return (
    <div className="info-page">
      <div className="container main">
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-12">
              <h1>{t(textPrefix + 'Title')}</h1>
              <p>{t(textPrefix + 'Intro')}</p>
              <p><strong>{t(textPrefix + 'Intro2')}</strong></p>
              <div className="form-group">
                <input type="text" onChange={inputFirstName} className="form-control"
                  placeholder={t('Account_FirstName')} value={firstName} name="firstName"
                  pattern="[A-Za-z ]{3,16}"
                  title="3 to 16 letters"
                  required />
              </div>
              {subscription?.needs_account && (
                <div className="form-group">
                  <input type="text" onChange={inputLastName} className="form-control"
                    placeholder={t('Account_LastName')} pattern="[A-Za-z ]{3,16}"
                    title="3 to 16 letters" value={lastName} name="lastName"
                    required />
                </div>
              )}
              <div className="form-group">
                <input type="email" onChange={inputEmail} className="form-control"
                  placeholder={t('Account_Email')} value={email} name="email" required />
              </div>
              {subscription?.needs_account && (
                <React.Fragment>
                  <div className="form-group">
                    <input type="password" onChange={inputPassword}
                      className="form-control" placeholder={t('Account_Password')}
                      value={password} name="password" required />
                  </div>
                  <div className="form-group">
                    <input type="password" onChange={inputPasswordRepeat}
                      className="form-control" placeholder={t('Account_PasswordRepeat')}
                      value={passwordRepeat} name="passwordRepeat" required />
                  </div>
                </React.Fragment>
              )}
              <div className="form-check">
                <input type="checkbox" checked={optinMail} onChange={changeOptinMail}
                  className="form-check-input" id="optin_mail" />
                <label className="form-check-label"
                  htmlFor="optin_mail">{t('Account_OptinMail')}</label>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={optinData} onChange={changeOptinData}
                  className="form-check-input" id="optin_data" />
                <label className="form-check-label"
                  htmlFor="optin_data">{t('Account_OptinData')}</label>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={policy} onChange={changePolicy}
                  className="form-check-input" id="policy" />
                <label className="form-check-label"
                  htmlFor="policy">{t('Account_Policy')}</label>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col mt-3 privacy-policy">
              <Trans i18nKey="subscription:Account_LinksToPolicies">
                See here our <TermsConditionsLink /> and <PrivacyPolicyLink />
              </Trans>
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-12">
              <button className={"btn btn-secondary" + (subscription?.needs_account ? ' gtm-create-account' : '')}>
                {t(subscription?.needs_account ? 'Account_Register' : 'Order_SendReport')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CheckoutData;
