import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import {
	Link
} from 'react-router-dom';
import TermsConditionsLink from "../Policy/TermsConditionsLink";
import PrivacyPolicyLink from "../Policy/PrivacyPolicyLink";
import Locale from "../../lib/Locale";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

  render() {
    return (
		<div className="footer container-fluid no-print">
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<h3>{this.props.t('FOOTER_Ourservices')}</h3>
						<ul>
								<li><Link to={Locale.getUrlForCurrentLocale("/bikefitting")}>{this.props.t('FOOTER_OnlineBikeFit')}</Link></li>
							<li><Link to={Locale.getUrlForCurrentLocale("/road")}>{this.props.t('FOOTER_RoadBikeDecisionAid')}</Link></li>
							<li><Link to={Locale.getUrlForCurrentLocale("/mtb")}>{this.props.t('FOOTER_MountainBikeDecisionAid')}</Link></li>
							<li><Link to={Locale.getUrlForCurrentLocale("/ebike")}>{this.props.t('FOOTER_EBikeDecisionAid')}</Link></li>
							<li className="mt-2">+31 854 012 539</li>
						</ul>
					</div>
					<div className="offset-md-1 col-md-4">
						<h3>{this.props.t('FOOTER_InformationAndTips')}</h3>
						<ul>
							<li><Link to={Locale.getUrlForCurrentLocale("/blog")}>{this.props.t('FOOTER_OverviewOfAllArticles')}</Link></li>
							<ul id="footer-sub-ul">
								<li><Link to={Locale.getUrlForCurrentLocale("/road-position")}>{this.props.t('FOOTER_ImportanceOfBikeFit')}</Link></li>
								<li><Link to={Locale.getUrlForCurrentLocale("/road-frame-size")}>{this.props.t('FOOTER_HowToDecideFrameSize')}</Link></li>
								<li><Link to={Locale.getUrlForCurrentLocale("/road-setup")}>{this.props.t('FOOTER_FineTuning')}</Link></li>
								<li><Link to={Locale.getUrlForCurrentLocale("/mtb-types")}>{this.props.t('FOOTER_MTBTypes')}</Link></li>
								<li><Link to={Locale.getUrlForCurrentLocale("/other-blogs")}>{this.props.t('FOOTER_Other')}</Link></li>
							</ul>
						</ul>
					</div>
					<div className="offset-md-1 col-md-3">
						<h3>{this.props.t('FOOTER_BestBikeAdvice')}</h3>
						<ul>
							<li><Link to={Locale.getUrlForCurrentLocale("/pricing")}>{this.props.t('FOOTER_Prices')}</Link></li>
							<li><Link to={Locale.getUrlForCurrentLocale("/contact")}>{this.props.t('FOOTER_ContactForm')}</Link></li>
							<div>
								<TermsConditionsLink />
							</div>
							<div>
								<PrivacyPolicyLink />
							</div>
							<li><Link to={Locale.getUrlForCurrentLocale("/cookie-and-data-setting")}>{this.props.t('FOOTER_CookieAndDataSettings')}</Link></li>
						</ul>
						
					</div>
				</div>
			</div>
		</div>
    );
  }
}

export default withTranslation('footer')(Footer);
