import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import './style.css'
import Api from '../../../../../lib/Api';

const EmailForm = () => {

  const { t } = useTranslation("newLandingPage")

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    Api.post('/free-guide', {
      email: email,
      name: name
    }).then(response => {
      if (response?.data?.response?.status === 1000) {
        alert(t("email_successfully_sent_check_inbox"))
      }
    }).catch(e => {
      alert(t("GeneralError"))
    }).finally(() => setLoading(false))
  }
  return (
    <Form className='w-100 w-md-75  mx-auto d-md-flex justify-content-center' id='mailBox' onSubmit={handleSubmit}>

      {/* name */}
      <Form.Group className="mr-3 p-0 col-md-auto col-12" controlId="formBasicEmail">
        <Form.Control
          className='px-2 px-md-5 py-4 text-l border-12px'
          type="text"
          placeholder={t("Naam")}
          name='name'
          required={true}
          value={name}
          onChange={event => setName(event.target.value.trimStart())}
        />
      </Form.Group >

      {/* email */}
      < Form.Group className="mb-3 p-0 col-md-auto col-12" controlId="formBasicEmail" >
        <Form.Control
          className='px-2 px-md-5 py-4 text-l border-radius'
          type="email"
          placeholder={t("Email adres")}
          name='email'
          required={true}
          onChange={event => setEmail(event.target.value)}
        />
      </Form.Group >


      <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
        <button disabled={loading} className={`px-md-4 p-md-0 p-2 px-3 border-0 text-white border-radius-right cta font-bold ${loading ? "" : "bg-dark-gray"}`}>
          {t("Verstuur")}
        </button>
      </Form.Group>

    </Form >
  );
};

export default EmailForm;