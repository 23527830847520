import React, { useState, useEffect } from 'react';
import { OpenFeatureContext } from './OpenFeatureContext';
import FlagsmithProvider from "./providers/flagsmith";
import { OpenFeature, ProviderEvents } from "@openfeature/js-sdk";
import Newsletter from "../lib/Newsletter";

const OpenFeatureProvider = ({ apiKey, apiHost, children }) => {
  const [openFeatureClient, setOpenFeatureClient] = useState(null);

  useEffect(() => {
    const flagsmithProvider = new FlagsmithProvider(apiKey, apiHost);
    OpenFeature.setProvider(flagsmithProvider);

    let context = {};
    if (Newsletter.getLocalEntry().email) {
      context = { ...context, ...Newsletter.getLocalEntry() }
      context['targetingKey'] = Newsletter.getLocalEntry().email;
    }

    OpenFeature.addHandler(ProviderEvents.Ready, () => {
      const client = OpenFeature.getClient();

      client.setContext(context);

      setOpenFeatureClient(client);
    })
  }, [apiKey]);

  return (
    <OpenFeatureContext.Provider value={{ openFeatureClient }}>
      {children}
    </OpenFeatureContext.Provider>
  );
};

export default OpenFeatureProvider;
