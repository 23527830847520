import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Footer from "../../../components/Footer";
import Carousel from "../components/Carousel";
import "../styles.css";
import DocumentMeta from "react-document-meta";
import ReactGA from 'react-ga4';
import { hasSavedBikefit, startBikefit } from "../../../components/Bikefit/lib/bikefit";
import Trustpilot from "../../../components/Reviews/Trustpilot";
import ExpertReviewCarousel from "../components/ExpertReviewCarousel";
import OurToolsCarousel from "../components/OurToolsCarousel";
import VideoPlayer from "./videoPopup.jsx";
import Popup from "../../../lib/Popup";
import Locale from "../../../lib/Locale";
import Redirections from "../../../lib/Redirections";
import { Link } from 'react-router-dom';


const Home = () => {
  const [t, i18n] = useTranslation('home');
  const mtbAdviceUrl = Locale.getUrlForCurrentLocale("/mtb/advice/experience");
  const roadAdviceUrl = Locale.getUrlForCurrentLocale("/road/advice/experience");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const videoPopup = () => {
    let videoPlayer = <VideoPlayer />;
    Popup.show(videoPlayer, true);
  };
  const meta = {
    title: t("CompanyHome_findyourbike"),
    description: t("CompanyHome_answerquestions"),
  };

  const bikefitClicked = () => {
    ReactGA.event({
      action: "click",
      category: "bikefit_home_start",
      label: `Start bikefit from the home page`,
      value: 0,
    });
    startBikefit();
  };

  const onBikefitResultsClicked = () => {
    if (hasSavedBikefit()) {
      Redirections.goTo('/bikefit/result-saved');
    } else {
      alert(t('CompanyHome_NoSavedBikefit'))
    }
  }

  const language = i18n.language.split('-')[0]

  return (
    <div className={"home " + language}>
      <DocumentMeta {...meta} />
      <div className="hero home-hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7">
              <Trans i18nKey="home:CompanyHome_Headline">
                {t('CompanyHome_Headline')}
              </Trans>
              <p>{t('CompanyHome_Subtitle')}</p>
              <ul className="list-inline">
                <li className="list-inline-item">{t('CompanyHome_RoadBike')}</li>
                <li className="list-inline-item">{t('CompanyHome_MountainBike')}</li>
                <li className="list-inline-item">{t('CompanyHome_GravelBike')}</li>
              </ul>
              <button onClick={bikefitClicked} className="btn btn-primary">{t('CompanyHome_Start_Bikefit')}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bikefinder-promo home-bikefinder-promo">
          <h1 className="bikefinder-promo-title">{t("CompanyHome_findyourbike")}</h1>
          <p className="bikefinder-promo-subtitle">{t("CompanyHome_answerquestions")}</p>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="bikefinder-promo-block bikefinder-promo-mtb">
                <div className="black-overlay"></div>
                <h3>{t('CompanyHome_MountainBike')}</h3>
                <Link to={mtbAdviceUrl} className="btn btn-white">{t('CompanyHome_Bikefinder_CTA')}</Link>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="bikefinder-promo-block bikefinder-promo-road">
                <div className="black-overlay"></div>
                <h3>{t('CompanyHome_RoadBike')}</h3>
                <Link to={roadAdviceUrl} className="btn btn-white">{t('CompanyHome_Bikefinder_CTA')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="customer-reviews home-customer-reviews text-center">
        <div className="home-customer-reviews-title">{t('CompanyHome_CustomerReview_Title')}</div>
        <h2 className="home-customer-reviews-subtitle">{t('CompanyHome_CustomerReview_Subtitle')}</h2>
        <div className="trustpilot-wrapper">
          <Trustpilot />
        </div>
      </div>
      <div className="expert-reviews home-expert-reviews text-center">
        <h2 className="home-customer-reviews-subtitle">{t('CompanyHome_ExpertReview_Title')}</h2>
      </div>
      <div className="home-professional-reviews">
        <div className="container">
          <ExpertReviewCarousel />
        </div>
      </div>
      <div className="main">
        <div className="container">
          <div className="col-md-12 mt-5 pt-3">
            <div className="our-services-heading">
              <h5>{t('CompanyHome_OurServices')}</h5>
              <h2>{t('CompanyHome_OurTools')}</h2>
            </div>
          </div>
          <div className="row mt-4 our-tools br-10">
            <div className="col-md-6" style={{ borderRight: '1px solid #26242426' }}>
              <div className="services-left">
                <div className="services-left-heading">
                  <div className="left-heading-content"><img src="/img/home/search.jpg" alt="search" /><h4>{t('CompanyHome_BikeFinder')}</h4></div>
                  <div className="left-heading-line"><img src="/img/home/hrline.jpg" alt="line" /></div>
                  <div className="left-heading-subheading mt-3"><h5>{t('CompanyHome_findyourbike')}</h5></div>
                </div>
                <Link to={Locale.getUrlForCurrentLocale('/road')}><div className="service service-1 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_RoadBike')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_FindRoadBike_Desc')}</p>
                  </div>
                </div></Link>
               <Link to={Locale.getUrlForCurrentLocale('/mtb')} > <div className="service service-2 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_MountainBike')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_FindMountainBike_Desc')}</p>
                  </div>
                </div></Link>
                <Link to={Locale.getUrlForCurrentLocale('/ebike')}><div className="service service-3 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_EBike')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_What_EBike_Specs')}</p>
                  </div>
                </div></Link>
                <Link to={Locale.getUrlForCurrentLocale('/compare-bikes')}><div className="service service-4 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_BikeComparison')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_BikeComparison_Details')}</p>
                  </div>
                </div></Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="services-right">
                <div className="services-right-heading">
                  <div className="right-heading-content"><img src="/img/home/ruler.jpg" alt="ruler" /><h4>{t('CompanyHome_BikeFit')}</h4></div>
                  <div className="right-heading-line"><img src="/img/home/hrline.jpg" alt="line" /></div>
                  <div className="right-heading-subheading mt-3"><h5>{t('CompanyHome_BikeFit_Title')}</h5></div>
                </div>
                <Link to={Locale.getUrlForCurrentLocale('/bikefitting')}><div className="service service-5 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_OnlineBikeFit')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_OnlineBikeFit_Desc')}</p>
                  </div>
                </div></Link>
               <Link to={Locale.getUrlForCurrentLocale('/frame-advice')}> <div className="service service-6 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_FrameSizeAdvice')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_FramemaatAdvies_Desc')}</p>
                  </div>
                </div></Link>
                <Link to={Locale.getUrlForCurrentLocale('/cockpit-advice')}><div className="service service-7 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_CockpitSetup')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_CockpitAdvies_Desc')}</p>
                  </div>
              </div></Link>
              <div onClick={onBikefitResultsClicked} className="service service-8 mt-5">
                  <div className="black-overlay"></div>
                  <div className="service-btn mb-4"><button  type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
                  <div className="service-content"><h5>{t('CompanyHome_BikeFitResults')}</h5>
                    <hr className="serviceshr" />
                    <p>{t('CompanyHome_Results')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-tools-mobile br-10">
            <OurToolsCarousel onBikefitResultsClicked={onBikefitResultsClicked} />
          </div>
        </div>
        <div className="our-services">
          <div className="container">
            <h2>{t('CompanyHome_OurServicesAre')}</h2>
            <h6>{t("CompanyHome_Personaltitle")}</h6>
            <div className="row">
              <div className="card mt-5 mb-5 mr-auth mr-lg-4 br-10">
                <img className="card-img-top br-10" src="img/silhouette/body_met_mtb.png" alt="mountainbike silhouette" />
                <div className="card-body">
                  <h6 style={{ fontWeight: 700 }}>{t('CompanyHome_TailoredAdvise')}</h6><hr />
                  <p className="card-text" style={{ color: '#26242473' }}>{t('CompanyHome_TailoredAdvise_Desc')}</p>
                </div>
              </div>
              <div className="card mt-5 mb-5 mr-auth mr-lg-4 br-10">
                <div className="card-img-top br-10">
                  <div className="home-column-inner slider">
                    <Carousel />
                  </div>
                </div>
                <div className="card-body">
                  <h6 style={{ fontWeight: 700 }}>{t('CompanyHome_Compare')}</h6><hr />
                  <p className="card-text" style={{ color: '#26242473' }}>{t('CompanyHome_WeCompare')}</p>
                </div>
              </div>
              <div className="card mb-5 mt-5 br-10" id="card-3">
                <img className="card-img-top br-10" src="img/home-transparant.jpg" alt="Bikefinder preview" />
                <div className="card-body">
                  <h6 style={{ fontWeight: 700 }}>{t('CompanyHome_TransparentResults')}</h6><hr />
                  <p className="card-text" style={{ color: '#26242473' }}>{t('CompanyHome_WeGiveAdvice')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="video-section">
          <div className="container">
            <div className="home-video-img-div">
              <img src="/img/home/home-video-poster.jpg" onClick={videoPopup} alt="video thumbnail" className="w-100 br-10 videoPoster d-none d-md-block" />
              <img src="/img/home/home-video-poster-mobile.jpg" onClick={videoPopup} alt="video thumbnail" className="w-100 br-10 videoPoster d-md-none" />
              <header className="text-center home-video-img-div-title">
                <h2>{t("CompanyHome_VideoPosterTitle")} </h2>
              </header>
              <h6 className="home-video-img-div-desc">{t("CompanyHome_VideoPoster_Desc")}</h6>
            </div>
            <div id="modelView"></div>
            <div className="video-section-content">
              <div className="row">
                <div className="col-sm-12 framefit_info">
                  <header className="prime_title">
                    <h2>{t("CompanyHome_NeverWrongBike")} </h2>
                  </header>
                  <p>{t("CompanyHome_WhatBike")}</p>
                  <p>{t("CompanyHome_AGoodShop")}.</p>
                  <p>{t("CompanyHome_FindOnline")}</p>
                  <p>{t("CompanyHome_FrameFitCombines")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
