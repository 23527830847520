import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from "./components/Nav";
import Popup from "./components/Popup";
import Home from "./pages/Homes/Home";

import { Routes as SubscriptionRoutes } from "./components/Subscription";
import BikePartners from "./pages/BikefitPartners";
import ConfirmAccount from "./pages/ConfirmAccount";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import InfoDisclaimer from "./pages/Info/InfoDisclaimer";
import InfoFineTune from "./pages/Info/InfoFineTune";
import InfoFitScore from "./pages/Info/InfoFitScore";
import InfoFramefitFineTune from "./pages/Info/InfoFramefitFineTune";
import InfoMeasureDefinitions from "./pages/Info/InfoMeasureDefinitions";
import InfoMtbTypes from "./pages/Info/InfoMtbTypes";
import InfoPosture from "./pages/Info/InfoPosture";
import InfoSuspension from "./pages/Info/InfoSuspension";
import ReviewPage from "./pages/Info/infoReview";

import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import { config } from "./config";

import { useTranslation } from "react-i18next";
import MoreInfo from "./components/MoreInfo";
import Tracking from "./components/Tracking";
import BikeComperision from "./pages/BikeComparision";
import CockpitAdvice from "./pages/CockpitAdvice";
import Cookies from "./pages/Cookies";
import CookieBanner from "./components/Tracking/CookieBanner";
import FramemaatAdvice from "./pages/FramemaatAdvice";
import NoCookies from "./pages/NoCookies";
import NotFound from "./pages/NotFound";
import Pricing from "./pages/Pricing";
import ResetPassword from "./pages/ResetPassword";

import Loader from "./components/Loader";
import Auth from "./lib/Auth";
import Head from "./lib/Head";
import Newsletter from "./lib/Newsletter";
import BlogOverview from "./pages/Blog/BlogOverview";
import OtherBlogs from "./pages/Blog/BlogOverview/OtherBlogs";
import SingleBlog from "./pages/Blog/SingleBlog";
import NewLandingPage from "./pages/NewLandingPage/NewLandingPage";
import Redirections from './lib/Redirections';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainDomain, selectWhitelabelDomain } from './redux/selectors/whitelabel';
import { loadWhitelabelInfo } from './redux/actions/whitelabel';
import FreeReport from './pages/Free-resource/FreeReport';

const Trajectory = React.lazy(() => import('./components/Bikefinder/Trajectory'));
const BikefitRouter = React.lazy(() => import('./components/Bikefit/BikefitRouter'));
const EbikeRouter = React.lazy(() => import('./components/Ebike/Routes'));

axios.defaults.baseURL = config.rootUrl;

const App = (props) => {
  const location = useLocation();
  const dispatch = useDispatch()

  const [t,] = useTranslation("common")
  const [loading, setLoading] = useState(true);

  const isWhiteLabelSubdomain = useSelector(selectWhitelabelDomain);
  const isMainDomain = useSelector(selectMainDomain);

  useEffect(
    () => {
      Head.addHreflangLinks()
      Head.addCanonicalTag()
    },
    [location]
  )

  useEffect(() => {
    dispatch(loadWhitelabelInfo())
  }, [loadWhitelabelInfo])

  // Redirect to /bikefitting if not on main domain or subdomain with /bikefitting or /bikefit
  useEffect(() => {
    if (isMainDomain) {
      setLoading(false);
    } else if (isWhiteLabelSubdomain) {
      if (
        location.pathname.includes("/img") ||
        location.pathname.includes("/static") ||
        location.pathname.includes("/bikefit")
      ) {
        setLoading(false);
      } else {
        Redirections.replaceTo("/bikefit/bike-type");
      }
    }
  }, [isMainDomain, isWhiteLabelSubdomain, location.pathname])

  useEffect(() => {
    if (Auth.isLoggedIn()) {
      console.log('Am logged in. Token: ' + Auth.getToken());
      Auth.checkLogin()
        .then(() => {
          Newsletter.fetchLocalEntry()
        })
        .catch(status => {
          console.log('Checked stupid token, does not work: ' + Auth.getToken());
          if (status === 401) {
            alert(t('SessionExpired'))
          }
          Auth.clearData();
          window.location.reload()
        })
    }
  });

  if (loading) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader />}>
      <Nav />
      <Popup />
      <MoreInfo />
      <Tracking history={props.history} />
      <Switch>

        <Route exact path="(/...?/..)?/" render={() => <Home />} />
        <Route exact path="(/...?/..)?/bikefitting" render={() => <NewLandingPage />} />
        <Route
          path="(/...?/..)?/contact"
          render={() => <Contact history={props.history} />}
        />
        <Route
          path="(/...?/..)?/confirm"
          render={() => <ConfirmAccount history={props.history} />}
        />

        <Route
          path="(/...?/..)?/reset"
          render={() => <ResetPassword history={props.history} />}
        />
        <Route path="(/...?/..)?/free-resource" render={() => <FreeReport />} />
        <Route path="(/...?/..)?/faq" render={() => <Faq />} />
        <Route path="(/...?/..)?/pricing" render={() => <Pricing />} />
        <Route path="(/...?/..)?/cookie-and-data-setting" render={() => <Cookies />} />
        <Route path="(/...?/..)?/bikepartners" render={() => <BikePartners />} />

        <Route path="(/...?/..)?/info/mtb-types" render={() => <InfoMtbTypes />} />
        <Route path="(/...?/..)?/info/suspension" render={() => <InfoSuspension />} />
        <Route path="(/...?/..)?/info/posture" render={() => <InfoPosture />} />
        <Route path="(/...?/..)?/info/fine-tune" render={() => <InfoFineTune />} />
        <Route
          path="(/...?/..)?/info/framefit-fine-tune"
          render={() => <InfoFramefitFineTune />}
        />
        <Route
          path="(/...?/..)?/info/measure-definitions"
          render={() => <InfoMeasureDefinitions />}
        />
        <Route path="(/...?/..)?/info/fitscore" render={() => <InfoFitScore />} />
        <Route path="(/...?/..)?/info/disclaimer" render={() => <InfoDisclaimer />} />
        <Route
          path="(/...?/..)?/info/review"
          render={() => <ReviewPage history={props.history} />}
        />

        <Route path="(/...?/..)?/subscription" component={SubscriptionRoutes} />
        <Route path="(/...?/..)?/nocookies" component={NoCookies} />
        <Route path="(/...?/..)?/compare-bikes" component={BikeComperision} />
        <Route path="(/...?/..)?/frame-advice" component={FramemaatAdvice} />
        <Route path="(/...?/..)?/cockpit-advice" component={CockpitAdvice} />

        <Route path="(/...?/..)?/mtb"
          component={({ match }) => (<Trajectory traject="mtb" path={match.url} history={props.history} />)} />
        <Route path="(/...?/..)?/road"
          component={({ match }) => (<Trajectory traject="race" path={match.url} history={props.history} />)} />
        <Route path="(/...?/..)?/bikefit" component={BikefitRouter} />
        <Route path="(/...?/..)?/landing/bikefit" component={BikefitRouter} />
        <Route path="(/...?/..)?/ebike" component={EbikeRouter} />

        <Route path="(/...?/..)?/blog" component={BlogOverview} />
        <Route path="(/...?/..)?/other-blogs" component={OtherBlogs} />

        <Route path="(/...?/..)?/:slug" component={SingleBlog} />
        <Route component={NotFound} />


      </Switch>
      <CookieBanner />
    </Suspense>
  );
};

export default App;
